<template>
  <div class="col p-0 d-flex flex-column align-items-center justify-content-center">
    <JayaTitle classType="page-title" titleName="A propos"/>
    <b-card class="col col-md-10 col-lg-8 col-xl-6 mt-1 text-justify p-0">
      <TextBlock title="Hortilio"/>

      <p>
        Conçue par
        <a @click="$openLinkJaya('https://www.cdc-biodiversite.fr/')" class="text-primary">CDC biodiversité</a>
        et
        <a @click="$openLinkJaya('https://jaya-garden.fr/')" class="text-primary">JAYA SAS</a>,
        Hortilio est une application numérique pédagogique au service des jardiniers qui vise à favoriser la
        biodiversité en diffusant auprès de tous les bonnes pratiques au jardin.
      </p>
      <p>
        Hortilio est également utilisée par les collectivités territoriales pour informer les utilisateurs des bonnes
        pratiques en faveur de la biodiversité (évènements, fiches, ...)
        Des statistiques anonymisées sur les jardins des particuliers sont transmises aux collectivités, pour qu'elles
        adaptent leurs stratégies "Biodiversité" aux particularités de leurs territoires.
        Ces données comprennent: les pratiques de jardinage, les espèces plantées, les récoltes obtenues et les réponses
        aux quiz de biodiversité.
      </p>

      <TextBlock title="CDC Biodiversité et Jaya"/>
      <p>
        <strong>CDC Biodiversité</strong>
        met en œuvre des actions concrètes de restauration et préservation de la biodiversité, dont elle assure la
        gestion pérenne. Créée en 2007, CDC Biodiversité est une filiale de la Caisse des Dépôts. Pionnière sur la
        compensation écologique, elle poursuit sa mission d’intérêt général en développant des solutions innovantes qui
        concilient développement économique et biodiversité et adaptées aux besoins des acteurs publics et privés.
      </p>
      <p>
        <strong>JAYA</strong>
        a été créé par de jeunes ingénieurs réunis autour du développement durable. Leur ambition est d’apporter une
        réponse concrète, réaliste et confiante aux défis contemporains. Cette réponse a pris la forme d’un projet en
        économie sociale et solidaire visant à la mise en place de conditions ayant pour objectif l’émergence d’une
        biodiversité durable sur le territoire.
      </p>

      <TextBlock title="Les données libres"/>
      <p>
        Nos bases de données reposent en partie sur des bases ouvertes. Nous remercions les organismes les ayant créées.
      </p>

      <h5 class="text-secondary"><a class="underline cursor-pointer text-primary" @click="$openLinkJaya('https://plants.usda.gov/')"> The Plant
        Database, USDA </a></h5>
      <p>
        USDA, NRCS. 2020. The PLANTS Database. National Plant Data Team, Greensboro, NC 27401-4901 USA.
      </p>

      <h5 class="text-secondary"><a class="underline cursor-pointer text-primary" @click="$openLinkJaya('https://www.wikidata.org/')">
        Wikidata </a></h5>
      <p>
        Wikidata est une base de connaissances gratuite et ouverte qui peut être lue et éditée par les humains et les
        machines.
        Wikidata sert de stockage central pour les données structurées de ses projets frères Wikimedia, notamment
        Wikipédia, Wikivoyage, Wiktionary, Wikisource et autres.
      </p>

      <h5 class="text-secondary"><a class="underline cursor-pointer text-primary" @click="$openLinkJaya('https://www.globalbioticinteractions.org/')">
        Global Biotic Interactions
      </a></h5>
      <p>
        Global Biotic Interactions (GloBI) offre un accès libre à la recherche de données d'interaction entre les
        espèces (par exemple, prédateur-proie, pollinisateur-plante, pathogène-hôte, parasite-hôte) en combinant des
        ensembles de données ouverts existants à l'aide d'un logiciel open source.
      </p>
    </b-card>
  </div>
</template>


<script>
import JayaTitle from '@/components/global/JayaTitle.vue'
import TextBlock from '@/components/global/JayaTextBlock.vue'
import {BCard} from "bootstrap-vue"

export default {
  name: 'about-us',
  components: {
    JayaTitle,
    TextBlock,
    BCard
  }
}
</script>

<style lang="scss">
</style>
